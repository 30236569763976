import React, { useState, useEffect } from 'react';
import PriceComparisonTable from './PriceComparisionTable';
import './App.css'; // Import the CSS file
import CountryDataDisplay from './CountryDataDisplay';

function App() {
  const [data, setData] = useState({});
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    if (searchKeyword) {
      fetch(`https://mbqx4944l2.execute-api.us-east-1.amazonaws.com/Dev?search=${searchKeyword}`)
        .then(response => response.json())
        .then(data => {
          setData(data);
          setIsLoading(false); // Stop loading once data is fetched
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setIsLoading(false); // Stop loading on error
        });
    }
  }, [searchKeyword]);

  const handleSearch = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const search = formData.get('search');
    setSearchKeyword(search);
    setIsLoading(true); // Start loading
    // Assuming you might call the API here or in useEffect
  };

  const styles = {
    app: {
      textAlign: 'center',
      fontFamily: 'Arial, sans-serif',
      backgroundColor: '#f0f2f5',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    searchForm: {
      margin: '20px 0',
    },
    searchInput: {
      padding: '10px',
      fontSize: '16px',
      border: 'none',
      borderRadius: '20px 0 0 20px',
      outline: 'none',
    },
    searchButton: {
      padding: '10px 20px',
      fontSize: '16px',
      border: 'none',
      backgroundColor: '#007bff',
      color: 'white',
      cursor: 'pointer',
      borderRadius: '0 20px 20px 0',
      outline: 'none',
    },
    progressBar: {
      height: '20px',
      width: '100%',
      backgroundColor: '#f3f3f3',
      borderRadius: '5px',
      margin: '20px 0',
    },
  };
  
  return (
    <div className="App" style={styles.app}>
      <h1>Unified Shopping</h1>
      <form onSubmit={handleSearch} style={styles.searchForm}>
        <input
          name="search"
          type="text"
          placeholder="Search for products..."
          style={styles.searchInput}
        />
        <button type="submit" style={styles.searchButton}>
          Search
        </button>
      </form>
      <h3>Showing price comparison for searched item: {searchKeyword}</h3>
      {!isLoading && Object.keys(data).length > 0 ? (
        <CountryDataDisplay countryData={data} />
      ) : isLoading ? (
        <div className="progress-bar">
          <div className="progress"></div>
        </div>
      ) : null}
    </div>
  );
}

export default App;