import React from 'react';
import OfferDisplay from './OfferDisplay';
import './ProductDataDisplay.css'; // Import the CSS file

const ProductDataDisplay = ({ productName, productDetails }) => {
  return (
    <div className="product-container">
      <h3 className="product-name">{productName}</h3>
      <div className="offer-list">
        {productDetails.offers.map((offer, index) => (
          <OfferDisplay key={index} offer={offer} />
        ))}
      </div>
    </div>
  );
};

export default ProductDataDisplay;