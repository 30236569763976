import React from 'react';

function PriceComparisonTable({ data }) {

  console.log(data)
  return (
    <>
      <style jsx>{`
        .comparisonTable {
          margin: 20px auto;
          width: 90%;
          border-collapse: collapse;
        }

        .comparisonTable th, .comparisonTable td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }

        .comparisonTable th {
          background-color: #04AA6D;
          color: white;
        }

        .comparisonTable tr:nth-child(even){background-color: #f2f2f2;}

        .comparisonTable tr:hover {background-color: #ddd;}

        .comparisonTable h2 {
          color: #333;
        }

        .link {
          color: #0645AD;
          text-decoration: none;
        }
      `}</style>
      <div>
        {Object.entries(data).map(([country, countryData]) => (
          <div key={country} className="comparisonTableContainer">
            <h2>{country}</h2>
            <table className="comparisonTable">
              <thead>
                <tr>
                  <th>Sold by</th>
                  <th>Title</th>
                  <th>Item Price</th>
                  <th>Total Price</th>
                  <th>Details & Special Offer</th>
                  <th>Links</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(countryData.data).map(([productName, productDetails]) =>
                  productDetails.offers.map((offer, index) => (
                    <tr key={index}>
                      <td>{offer['Sold by']}</td>
                      <td>{offer['Item price']}</td>
                      <td>{offer['Total price']}</td>
                      <td>{offer['Details & special offers']}</td>
                      <td><a href={offer['Link']} target="_blank" rel="noopener noreferrer" className="link">Click here</a></td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </>
  );
}

export default PriceComparisonTable;