import React from 'react';
import './OfferDisplay.css'; // Import the CSS file

const OfferDisplay = ({ offer }) => {
  return (
    <div className="offer-container">
      <p className="offer-detail">
        <span className="label">Sold by:</span> {offer['Sold by']}
      </p>
      <p className="offer-detail">
        <span className="label">Item Price:</span> {offer['Item price']}
      </p>
      <p className="offer-detail">
        <span className="label">Total Price:</span> {offer['Total price']}
      </p>
      <p className="offer-detail">
        <span className="label">Details & Special Offers:</span>{' '}
        {offer['Details & special offers']}
      </p>
      <a
        href={offer['Link']}
        target="_blank"
        rel="noopener noreferrer"
        className="offer-link"
      >
        Click here
      </a>
    </div>
  );
};

export default OfferDisplay;