import React from 'react';
import ProductDataDisplay from './ProductDataDisplay';

const CountryDataDisplay = ({ countryData }) => {
  return (
    <div>
      {Object.entries(countryData).map(([country, countryDetails]) => (
        <div key={country}>
          <h2>{country}</h2>
          {Object.entries(countryDetails.data).map(([productName, productDetails]) => (
            <ProductDataDisplay
              key={productDetails.pid}
              productName={productName}
              productDetails={productDetails}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default CountryDataDisplay;